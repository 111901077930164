import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useRedirectWhenPspMerchantAccountChanges} from '@/hooks';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {ErrorCode, LocaleCode, formatAmount, isFormattedTrpcError} from '@zentact/common';
import {CurrencyCode} from '@zentact/common';
import {
  Breadcrumbs,
  FlatPillWithDot,
  FullPaymentDetailsSection,
  Loading,
  PaymentEventsList,
  Typography,
  displayPaymentStatusMap,
  paymentStatusToColor,
  useRequiredParams,
} from '@zentact/ui-tailwind';
import {useNavigate} from 'react-router-dom';

const getBreadCrumbs = (pspReferenceId: string) => [
  {name: t`Transactions`, href: RoutePath.TRANSACTIONS, current: false},
  {name: t`Payments`, href: RoutePath.PAYMENTS, current: false},
  {name: pspReferenceId, href: '#', current: true},
];

export const PaymentDetails = () => {
  const navigate = useNavigate();
  const {i18n} = useLingui();
  useRedirectWhenPspMerchantAccountChanges(RoutePath.PAYMENTS);

  const {pspReferenceId} = useRequiredParams<{pspReferenceId: string}>();

  const payment = trpc.payment.getPaymentByPspReferenceId.useQuery(
    {pspReferenceId},
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onError: error => {
        const errorCode = isFormattedTrpcError(error)
          ? error.data.errorCode
          : ErrorCode.ERROR_GENERIC;
        navigate(RoutePath.ERROR, {state: {errorCode}});
      },
    }
  );

  if (payment.isLoading || !payment.data) {
    return (
      <div className="flex items-center justify-center h-80">
        <Loading mode="inline" />
      </div>
    );
  }
  const formattedAuthorizedAmount = formatAmount(
    payment.data.authorizedAmount,
    i18n.locale as LocaleCode,
    payment.data.currency as CurrencyCode
  );

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs(pspReferenceId)} />
      <div className="my-4">
        <Typography variant="header-page" className="flex">
          <Trans># {pspReferenceId}</Trans>
        </Typography>
        <div className="flex items-center gap-x-2">
          <div className="text-3xl leading-normal text-gray-700">{formattedAuthorizedAmount}</div>
          <div>
            <FlatPillWithDot
              color={paymentStatusToColor[payment.data.status] || 'blue'}
              label={displayPaymentStatusMap[payment.data.status] ?? payment.data.status}
            />
          </div>
        </div>
      </div>
      <div className="py-5 mb-5 bg-white rounded-xl md:col-span-2">
        <div className="px-4 pb-5 mb-5 border-b border-gray-200 sm:px-6">
          <Typography variant="header-section">
            <Trans>Payment Details</Trans>
          </Typography>
        </div>
        <div className="px-4 sm:px-6">
          <FullPaymentDetailsSection
            payment={payment.data}
            merchantAccountName={payment.data.merchantAccount.businessName}
            merchantPath={RoutePath.MERCHANT_DETAILS}
            organizationPath={RoutePath.CUSTOMERS_MERCHANTS}
            organizationName={payment.data.organization.name}
            showProfitAndProcessingFee={true}
            isAdmin={true}
          />
        </div>
      </div>
      <div className="py-5 bg-white rounded-xl md:col-span-2">
        <div className="px-4 pb-5 mb-5 font-medium leading-6 border-b border-gray-200 sm:px-5">
          <Typography variant="header-section">
            <Trans>Activity</Trans>
          </Typography>
        </div>
        <div className="px-4 sm:px-6">
          <PaymentEventsList payment={payment.data} />
        </div>
      </div>
    </div>
  );
};
