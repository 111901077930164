import {useLingui} from '@lingui/react';
import {MerchantAccountDetailsOutput} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {MerchantAccountPublicStatus, formatLocaleDate} from '@zentact/common';
import {DateTime} from 'luxon';
import {Link, generatePath} from 'react-router-dom';

type Props = {
  merchantAccount: MerchantAccountDetailsOutput;
  storeDetailsPath: string;
};

export const MerchantStoresList = ({merchantAccount, storeDetailsPath}: Props) => {
  const {i18n} = useLingui();
  return (
    <div>
      {merchantAccount.stores.map((store, i) => (
        <div key={`store-${i}`} className="flex gap-2 text-sm leading-6">
          <Link
            className="text-primary-600"
            to={generatePath(storeDetailsPath, {storeId: store.id})}
          >
            {store.displayName}
          </Link>
          <span>—</span>
          {i18n._('Active')}
          <span>—</span>
          {formatLocaleDate(store.createdAt, DateTime.DATE_MED)}
          {!store.balanceAccountId &&
            merchantAccount.status === MerchantAccountPublicStatus.ACTIVE && (
              <span className="font-medium text-red-600">{i18n._('NO BALANCE ACCOUNT SET')}</span>
            )}
        </div>
      ))}
    </div>
  );
};
