import {useLingui} from '@lingui/react';
import {PaymentDetailsOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CurrencyCode,
  LocaleCode,
  capitalizeCustomAttribute,
  formatAmount,
  formatLocaleDate,
  formatPercentage,
  formatUrlQueryParams,
  paymentMethodVariantTranslationMap,
} from '@zentact/common';
import {DateTime} from 'luxon';
import {Link, generatePath} from 'react-router-dom';
import {FlatPillWithDot} from '../../elements';
import {PaymentMethodIcon, paymentSourceMap} from '../../icons';
import {
  displayPaymentSourceMap,
  displayPaymentStatusMap,
  paymentStatusToColor,
} from './payment-list/columns';

type FullPaymentDetailsSectionProps = {
  payment: PaymentDetailsOutput | null;
  merchantAccountName: string;
  organizationName?: string;
  showProfitAndProcessingFee?: boolean;
  merchantPath: string;
  organizationPath?: string;
  isAdmin?: boolean;
  shopperRoute?: string;
};

export const FullPaymentDetailsSection = ({
  payment,
  merchantAccountName,
  showProfitAndProcessingFee,
  organizationName,
  merchantPath,
  organizationPath,
  isAdmin,
  shopperRoute,
}: FullPaymentDetailsSectionProps) => {
  const {i18n} = useLingui();

  if (!payment) {
    return null;
  }
  const {
    pspReferenceId,
    reference,
    pspMerchantAccountName,
    merchantAccountId,
    organizationId,
    authorizedAmount,
    refundedAmount,
    surchargeAmount,
    merchantSplitAmount,
    tipAmount,
    currency,
    status,
    paymentMethod,
    paymentMethodVariant,
    cardSummary,
    updatedAt,
    createdAt,
    checkout,
    source,
    ownerName,
    totalProcessingFee,
    store,
    merchantRefundedAmount,
    tenantRefundedAmount,
    paymentAttributes,
    authCode,
  } = payment;

  const formattedAuthorizedAmount = formatAmount(
    authorizedAmount,
    i18n.locale as LocaleCode,
    currency as CurrencyCode
  );

  return (
    <div className="leading-6 border-gray-100">
      <dl className="divide-y divide-gray-100">
        <div className="pb-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-900">{i18n._('PSP Transaction ID')}</dt>
          <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{pspReferenceId}</dd>
        </div>
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-900">{i18n._('Merchant Reference ID')}</dt>
          <dd className="mt-1 text-sm text-gray-700 break-all sm:col-span-2 sm:mt-0">
            {reference}
          </dd>
        </div>
        {!!authCode && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-900">{i18n._('Auth Code')}</dt>
            <dd className="mt-1 text-sm text-gray-700 break-all sm:col-span-2 sm:mt-0">
              {authCode}
            </dd>
          </div>
        )}
        {!!pspMerchantAccountName && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-900">{i18n._('PSP Merchant Account')}</dt>
            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
              {pspMerchantAccountName}
            </dd>
          </div>
        )}
        {!!organizationName && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-900">{i18n._('Organization')}</dt>
            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
              {organizationPath ? (
                <Link
                  to={`${organizationPath}?organizationId=${organizationId}`}
                  className="text-primary-600 hover:underline"
                >
                  {organizationName}
                </Link>
              ) : (
                organizationName
              )}
            </dd>
          </div>
        )}
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-900">{i18n._('Merchant Account')}</dt>
          <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
            <Link
              to={generatePath(merchantPath, {
                merchantAccountId: merchantAccountId,
              })}
              className="text-primary-600 hover:underline"
            >
              {merchantAccountName}
            </Link>
          </dd>
        </div>
        {!!ownerName && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
            <dt className="text-sm font-medium text-gray-900">{i18n._('Owner Name')}</dt>
            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{ownerName}</dd>
          </div>
        )}
        {checkout?.shopper?.merchantShopperId || checkout?.shopper?.email ? (
          <>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium text-gray-900">{i18n._('Customer ID')}</dt>
              <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                {shopperRoute && checkout.shopper?.merchantShopperId ? (
                  <Link
                    className="text-primary-600 hover:underline"
                    to={`${shopperRoute}?${formatUrlQueryParams({
                      merchantShopperId: checkout.shopper.merchantShopperId,
                    })}`}
                  >
                    <div className="break-all">{checkout.shopper.merchantShopperId}</div>
                  </Link>
                ) : (
                  i18n._('N/A')
                )}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium text-gray-900">{i18n._('Customer')}</dt>
              <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                {!!checkout?.shopper?.email && checkout.shopper.email}
              </dd>
            </div>
          </>
        ) : (
          !isAdmin &&
          checkout?.emailReceiptTo && (
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium text-gray-900">{i18n._('Email')}</dt>
              <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                {checkout?.emailReceiptTo}
              </dd>
            </div>
          )
        )}
        {tipAmount && tipAmount > 0 && (
          <>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium text-gray-900">{i18n._('Sales Amount')}</dt>
              <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                {formatAmount(
                  authorizedAmount - tipAmount,
                  i18n.locale as LocaleCode,
                  currency as CurrencyCode
                )}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
              <dt className="text-sm font-medium text-gray-900">{i18n._('Tip Amount')}</dt>
              <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                {formatAmount(tipAmount, i18n.locale as LocaleCode, currency as CurrencyCode)}
              </dd>
            </div>
          </>
        )}
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
          <dt className="text-sm font-medium text-gray-900">{i18n._('Authorized Amount')}</dt>
          <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
            {formattedAuthorizedAmount} {tipAmount && tipAmount > 0 && `(${i18n._('Sales + Tip')})`}
          </dd>
        </div>
        {merchantSplitAmount !== null && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
            <dt className="text-sm font-medium text-gray-900 whitespace-nowrap">
              {isAdmin
                ? i18n._('Transaction Fee ({percent})', {
                    percent: formatPercentage(
                      authorizedAmount,
                      authorizedAmount - merchantSplitAmount,
                      i18n.locale as LocaleCode
                    ),
                  })
                : i18n._('Transaction Fee')}
            </dt>
            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
              {formatAmount(
                authorizedAmount - merchantSplitAmount,
                i18n.locale as LocaleCode,
                currency as CurrencyCode
              )}
            </dd>
          </div>
        )}
        {!!surchargeAmount && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
            <dt className="text-sm font-medium text-gray-900">{i18n._('Surcharge Amount')}</dt>
            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
              {formatAmount(surchargeAmount, i18n.locale as LocaleCode, currency as CurrencyCode)}
            </dd>
          </div>
        )}
        {merchantSplitAmount !== null && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-900">{i18n._('Net Amount After Fees')}</dt>
            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
              {formatAmount(
                merchantSplitAmount,
                i18n.locale as LocaleCode,
                currency as CurrencyCode
              )}
            </dd>
          </div>
        )}
        {refundedAmount > 0 && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
            <dt className="text-sm font-medium text-gray-900">{i18n._('Refunded Amount')}</dt>
            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
              {formatAmount(refundedAmount, i18n.locale as LocaleCode, currency as CurrencyCode)}
            </dd>
          </div>
        )}
        {refundedAmount > 0 &&
          merchantSplitAmount !== null &&
          merchantRefundedAmount !== null &&
          tenantRefundedAmount !== null &&
          store?.splitConfigurationGroup?.refundSplitModel === 'REVERSE_PAYMENT_SPLIT' && (
            <>
              <div className="py-3 pl-2 sm:grid sm:grid-cols-3 sm:gap-4 ">
                <dt className="text-sm font-medium text-gray-900">
                  {i18n._('Refunded Transaction Fees')}
                </dt>
                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                  {formatAmount(
                    tenantRefundedAmount,
                    i18n.locale as LocaleCode,
                    currency as CurrencyCode
                  )}
                </dd>
              </div>
              <div className="py-3 pl-2 sm:grid sm:grid-cols-3 sm:gap-4 ">
                <dt className="text-sm font-medium text-gray-900">
                  {i18n._('Refunded Amount from Merchant')}
                </dt>
                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                  {formatAmount(
                    merchantRefundedAmount,
                    i18n.locale as LocaleCode,
                    currency as CurrencyCode
                  )}
                </dd>
              </div>
              <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
                <dt className="text-sm font-medium text-gray-900">{i18n._('Final Net Amount')}</dt>
                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                  {formatAmount(
                    merchantSplitAmount - merchantRefundedAmount,
                    i18n.locale as LocaleCode,
                    currency as CurrencyCode
                  )}
                </dd>
              </div>
            </>
          )}
        {showProfitAndProcessingFee && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
            <dt className="text-sm font-medium text-gray-900">{i18n._('Processing Cost')}</dt>
            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
              {totalProcessingFee
                ? `${formatAmount(
                    totalProcessingFee,
                    i18n.locale as LocaleCode,
                    currency as CurrencyCode
                  )} (${formatPercentage(
                    authorizedAmount,
                    totalProcessingFee,
                    i18n.locale as LocaleCode
                  )})`
                : i18n._('Pending')}
            </dd>
          </div>
        )}
        {showProfitAndProcessingFee &&
          totalProcessingFee !== null &&
          merchantSplitAmount !== null && (
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-900">{i18n._('Profit')}</dt>
              <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                {formatAmount(
                  authorizedAmount -
                    merchantSplitAmount -
                    totalProcessingFee -
                    (tenantRefundedAmount || 0),
                  i18n.locale as LocaleCode,
                  currency as CurrencyCode
                )}{' '}
                (
                {formatPercentage(
                  authorizedAmount,
                  authorizedAmount -
                    merchantSplitAmount -
                    totalProcessingFee -
                    (tenantRefundedAmount || 0),
                  i18n.locale as LocaleCode
                )}
                )
              </dd>
            </div>
          )}

        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-900">{i18n._('Payment Method')}</dt>
          <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
            <div className="flex items-center gap-2">
              <PaymentMethodIcon paymentType={paymentMethod} />
              {cardSummary ? (
                <span>*{cardSummary}</span>
              ) : paymentMethod === 'ach' ? (
                <span>ACH</span>
              ) : (
                <span />
              )}
            </div>
          </dd>
        </div>
        {!!paymentMethodVariant && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-900">
              {i18n._('Payment Method Variant')}
            </dt>
            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
              {paymentMethodVariantTranslationMap.get(paymentMethodVariant) ??
                capitalizeCustomAttribute(paymentMethodVariant)}
            </dd>
          </div>
        )}
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-900">{i18n._('Status')}</dt>
          <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
            <FlatPillWithDot
              color={paymentStatusToColor[status] || 'blue'}
              label={displayPaymentStatusMap[status] ?? status}
            />
          </dd>
        </div>
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-900">{i18n._('Source')}</dt>
          <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
            <div className="flex items-center gap-2">
              {paymentSourceMap[source]}
              {displayPaymentSourceMap[source]}
            </div>
          </dd>
        </div>
        {payment.terminalId && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-900">{i18n._('Terminal Id')}</dt>
            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
              {payment.terminalId}
            </dd>
          </div>
        )}
        {payment.possibleEnhancedSchemeDataLevel && (
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
            <dt className="text-sm font-medium text-gray-900">
              {i18n._("Card's Possible Data Level")}
            </dt>
            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
              {payment.possibleEnhancedSchemeDataLevel}(
              {payment.submittedEnhancedSchemeDataLevel
                ? payment.submittedEnhancedSchemeDataLevel ===
                  payment.possibleEnhancedSchemeDataLevel
                  ? i18n._('Submitted')
                  : `${payment.submittedEnhancedSchemeDataLevel} ${i18n._('Submitted')}`
                : i18n._('No data submitted')}
              )
            </dd>
          </div>
        )}
        {paymentAttributes.map(attribute => (
          <div key={attribute.name} className="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {capitalizeCustomAttribute(attribute.name)} (custom attribute)
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {attribute.value}
            </dd>
          </div>
        ))}
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
          <dt className="text-sm font-medium text-gray-900">{i18n._('Last updated')}</dt>
          <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
            {formatLocaleDate(updatedAt)}
          </dd>
        </div>
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
          <dt className="text-sm font-medium text-gray-900">{i18n._('Created')}</dt>
          <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
            {formatLocaleDate(createdAt)}
          </dd>
        </div>
        <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
          <dt className="text-sm font-medium text-gray-900">{i18n._('Created (UTC)')}</dt>
          <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
            {DateTime.fromISO(createdAt)
              .setZone('UTC')
              .toLocaleString(DateTime.DATETIME_MED)
              .concat(' (UTC)')}
          </dd>
        </div>
      </dl>
    </div>
  );
};
